import React, { useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import {
  motion,
  useTransform,
  useSpring,
  AnimatePresence
} from "framer-motion";
import { LinkButton } from "@components/Button";
import Stack from "@components/Stack";
// import Video from "@sections/Video";
import { VideoCarousel } from "@components/Carousel";

const springConfig = {
  stiffness: 15,
  damping: 15,
  bounce: 0.5
};

const initialRotate = 200;
const rotateDeg = 40;

interface IntroductionProps {
  ctaText?: string
  emphasizedWord?: string
  ctaSubtext?: string
}

function Introduction({ctaText, emphasizedWord, ctaSubtext}: IntroductionProps) {
  const x = useSpring(initialRotate, springConfig);
  const y = useSpring(initialRotate, springConfig);

  const rotateX = useTransform(
    y,
    [0, initialRotate * 2],
    [rotateDeg, -rotateDeg]
  );
  const rotateY = useTransform(
    x,
    [0, initialRotate * 2],
    [-rotateDeg, rotateDeg]
  );

  const areaRef = React.useRef<HTMLDivElement>(null);

  function handleMouse(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (areaRef.current) {
      // using a ref because event.target will switch to the values of the child div when you hover over it
      const rect = areaRef.current.getBoundingClientRect();
      // calculating the x and y position within the element
      x.set(event.clientX - rect.left);
      y.set(event.clientY - rect.top);
    }
  }

  function handleMouseExit() {
    x.set(initialRotate);
    y.set(initialRotate);
  }

  return (
    <IntroductionWrapper
      ref={areaRef}
      onMouseMove={handleMouse}
      onMouseLeave={handleMouseExit}
    >
      <LogoWrapper>
        <VideoContainer
          initial={{ opacity: 0, x: "-50%", y: "-50%", scaleX: 0.96 }}
          animate={{
            opacity: 1,
            scaleX: 1
          }}
        >
          <VideoCarousel />
        </VideoContainer>

        <BrandWrapper
          style={{ rotateX, rotateY }}
          initial={{ x: "-50%", y: "-50%", scale: 2 }}
        >
          <OpaqueLogo />
        </BrandWrapper>
      </LogoWrapper>
      <TextWrapper
        type="column"
        gap={3}
        style={{ gridAutoRows: "min-content" }}
      >
        <TopText>
          {emphasizedWord ? 
            ctaText?.split(' ').map(word => {
              if (word === emphasizedWord) {
                return <span>{emphasizedWord}</span>
              } else {
                return word + " "
              }
            })
          : 
            ctaText
          }
        </TopText>
        <DescriptionText>
          {ctaSubtext}
          {/* a new kind of qualitative research platform, with the{" "}
          <span>scale and speed</span> of quantitative research and the{" "}
          <span>intimacy</span> of ethnography, we reveal rich, candid truths
          about people by engaging them in private chat messaging */}
        </DescriptionText>
        <Stack type="row" gap={1.5}>
          <LinkButton to="https://news.sympler.co/contact-us">contact us</LinkButton>
          <LinkButton to="/brief">brief us</LinkButton>
        </Stack>
      </TextWrapper>
    </IntroductionWrapper>
  );
}

export default Introduction;

const IntroductionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 7rem;
  margin: 0 auto;
  position: relative;
  padding: 4rem 10%;
  perspective: 1000;

  @media (max-width: 950px) {
    flex-direction: column-reverse;
    padding: 5% 10%;
  }
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  flex-basis: 60%;

  svg {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 950px) {
    padding: 7rem;
  }
`;

const blurBrand = keyframes`
  0% {
    filter: blur(2px);
  }
  100% {
    filter: blur(20px);
  }
`;

const BrandWrapper = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
  opacity: 0.75;
  aspect-ratio: 1 / 1;
  animation: ${blurBrand} 4000ms ease-in-out;
  animation-fill-mode: forwards;
`;

const TextWrapper = styled(Stack)`
  max-width: 500px;
  flex-basis: 40%;
  @media (max-width: 950px) {
    /* border: 1px solid blue; */
    grid-area: 1 / 1 / -1/ -1;
    z-index: 1;
  }
`;

const flow = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
`;

const TopText = styled.h1`
  font-size: clamp(4.6rem, 4vw, 5.5rem);
  font-weight: 300;
  line-height: 1;
  font-family: "Open Sans", sans-serif;
  // color: hsl(0 0% 10%);
  color: rgb(99, 94, 192);
  letter-spacing: -2px;

  span {
    font-weight: 300;
    background: linear-gradient(
      90deg,
      #1f005c,
      #5b0060,
      #870160,
      #ac255e,
      #ca485c,
      #e16b5c,
      #f39060,
      #ffb56b
    );
    /* animation: ${flow} 15s linear infinite;
    animation-direction: alternate; */
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
`;

const DescriptionText = styled.div`
  font-size: 1.8rem;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0;
  color: rgb(99, 94, 192);
  span {
    font-style: italic;
    color: rgb(255, 105, 180);
  }
`;

function OpaqueLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 67 67">
      <path
        fill="url(#paint0_linear)"
        fillRule="evenodd"
        d="M66.48 28.592C64.16 12.29 49.958 0 33.436 0c-4.249 0-8.362.777-12.203 2.285-.427.167-.242.864.217.864 1.122 0 2.24.119 3.325.354 5.983 1.297 9.093 6.877 9.293 7.247l16.58 28.67c1.53 2.646 4.464 4.416 7.332 3.36 1.567-.577 3.226-1.506 4.588-3.003 4.302-4.729 3.936-10.859 3.912-11.185z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint1_linear)"
        fillRule="evenodd"
        d="M24.497 4.879a14.372 14.372 0 00-3.029-.321c-4.783 0-8.407 2.354-8.668 2.528C4.665 13.454 0 23.004 0 33.29c0 9.216 3.74 17.732 10.082 23.87.742.718 2.117-.488 1.8-1.471-1.877-5.836 1.442-11.328 1.648-11.66l16.608-28.62c1.55-2.669 1.605-6.131-.778-8.093C28.075 6.26 26.448 5.3 24.497 4.88z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint2_linear)"
        fillRule="evenodd"
        d="M63.57 40.814c-3.823 4.192-9.654 4.404-10.784 4.405-.093 0-.149-.002-.163-.002H19.472c-3.068 0-6.075 1.667-6.592 4.69-.28 1.636-.304 3.522.305 5.417 1.988 6.187 7.704 8.937 7.762 8.964l.064.03a33.34 33.34 0 0012.345 2.35c15.562 0 28.671-10.656 32.36-25.027.254-.993-1.455-1.584-2.146-.827z"
        clipRule="evenodd"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="18.782"
          x2="66.498"
          y1="37.324"
          y2="37.324"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7ECEFD"></stop>
          <stop offset="1" stopColor="#9EBDFF"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="10.071"
          x2="29.961"
          y1="10.189"
          y2="56.305"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FC7D49"></stop>
          <stop offset="1" stopColor="#FF788D"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="58.316"
          x2="18.117"
          y1="40.07"
          y2="64.589"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D1A5F6"></stop>
          <stop offset="1" stopColor="#FC89CB"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

const VideoContainer = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  video {
    width: 100%;
  }
`;
