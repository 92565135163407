import React from "react";
import styled, { keyframes } from "styled-components";
import Stack from "@components/Stack";

function ScrollDownToLearnMore() {
  return (
    <TextWrapper type="column" gap={2} center>
      <DescriptionText>
        <p>Scroll down to learn more</p>
      </DescriptionText>
    </TextWrapper>
  );
}

export default ScrollDownToLearnMore;

const TextWrapper = styled(Stack)`
  max-width: 100%;
  flex-basis: 40%;
`;

const DescriptionText = styled.div`
  p {
    font-size: 2rem;
    font-weight: lighter;
    color: rgb(99, 94, 192);
  }
`;
