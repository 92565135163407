import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";


class VideoCarousel extends Component {
  render() {
    return (
      <video controls>
        <source src='/videos_mp4/capturing_the_whole_human.mp4' type="video/mp4" />
      </video>
      // <StyledCarousel infiniteLoop interval={2000} showIndicators={false} centerMode>
      //   <div>
      //     <video controls autoPlay muted poster="./videos_mp4/healthcare.jpg">
      //       <source src="./videos_mp4/healthcare.mp4" type="video/mp4" />
      //     </video>
      //   </div>
      //   <div>
      //     <video controls poster="./videos_mp4/identity_politics.jpg">
      //       <source src="./videos_mp4/identity_politics.mp4" type="video/mp4" />
      //     </video>
      //     <p></p>
      //   </div>
      //   <div>
      //     <video controls poster="./videos_mp4/food_drink.jpg">
      //       <source src="./videos_mp4/food_drink.mp4" type="video/mp4" />
      //     </video>
      //   </div>
      //   <div>
      //     <video controls poster="./videos_mp4/T_T.jpg">
      //       <source src="./videos_mp4/T_T.mp4" type="video/mp4" />
      //     </video>
      //   </div>
      //   <div>
      //     <video controls poster="./videos_mp4/future_of_work.jpg">
      //       <source src="./videos_mp4/future_of_work.mp4" type="video/mp4" />
      //     </video>
      //   </div>
      //   <div>
      //     <video controls poster="./videos_mp4/retail.jpg">
      //       <source src="./videos_mp4/retail.mp4" type="video/mp4" />
      //     </video>
      //   </div>
      //   <div>
      //     <video controls poster="./videos_mp4/home_appliances.jpg">
      //       <source src="./videos_mp4/home_appliances.mp4" type="video/mp4" />
      //     </video>
      //   </div>
      // </StyledCarousel>
    );
  }
}

export { VideoCarousel };

const StyledCarousel = styled(Carousel)`
  .carousel .control-arrow {
    transition: all .1s ease-in;
    opacity: 1;
  }
`;