import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Introduction from "@sections/Introduction";
import ScrollDownToLearnMore from "@sections/ScrollDownToLearnMore";

import {
  motion,
  useViewportScroll,
  useMotionValue,
  useTransform,
  transform
} from "framer-motion";
import { useRange } from "@hooks";
import SEO from "@components/SEO";
import PageWrapper from "@components/PageWrapper";
import Orb from "@components/Orb";
import Sticky from "@components/Sticky";
import { LinkButton } from "@components/Button";
import { minZeroMaxOne } from "@utils";
import Markdown from "react-markdown";
import ScrollMore from "@components/ScrollMore";
import { navigate } from "gatsby";

interface IndexPageProps {
  location: Location;
}

const query = graphql`
  {
    strapiHomePage {
      Marketing_text,
      Main_header_text,
      Main_additional_text,
      emphasized_word
    }
  }
`;

function IndexPage({ location }: IndexPageProps) {
  const { strapiHomePage } = useStaticQuery(query);
  const { Marketing_text, Main_header_text, Main_additional_text, emphasized_word} = strapiHomePage;

  const textContent: string[] = Marketing_text.split(/\n/).filter(
    (text: string) => text
  );

  if (true) {

    if (typeof window !== "undefined") {
      navigate('/brief')
    }
    return <></>
  }
  const totalStickyProgress = useMotionValue(0);
  const inputRange = [0, 0.5, 0.8, 1];
  const { scrollYProgress } = useViewportScroll();
  const progressBarOpacity = useTransform(
    scrollYProgress,
    [0, 0.065, 1],
    [0, 0.7, 1]
  );

  const x = useTransform(totalStickyProgress, inputRange, [
    "-50%",
    "0%",
    "0%",
    "0%"
  ]);
  const y = useTransform(totalStickyProgress, inputRange, [
    "-55%",
    "0%",
    "100%",
    "100%"
  ]);
  const scale = useTransform(totalStickyProgress, inputRange, [0.5, 1, 3, 3]);
  const opacity = useTransform(totalStickyProgress, inputRange, [0.5, 1, 1, 1]);
  const rotate = useTransform(
    totalStickyProgress,
    inputRange,
    [-540, 0, 0, -45]
  );

  const [shouldShowTextContent, textProgress, textOpacity] = useRange(
    [-0.1, 1],
    totalStickyProgress
  );

  return (
    <PageWrapper>
      <SEO title="Sympler" pathname={location.pathname} />

      <Stack style={{ position: "relative", zIndex: 1 }}>
        <Introduction ctaText={Main_header_text} ctaSubtext={Main_additional_text} emphasizedWord={emphasized_word}/>
        <ScrollDownToLearnMore />
        <Sticky
          height={`${textContent.length * 850}px`}
          cover
          render={({ progress }) => {
            totalStickyProgress.set(progress);

            return (
              <StickyItem>
                <OrbSectionContainer>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                      zIndex: 2
                    }}
                  >
                    {shouldShowTextContent && (
                      <TextContainer>
                        {textContent.map((text, index) => {
                          const total = textContent.length;

                          const currentStaggered = minZeroMaxOne(
                            textProgress - index / total
                          );

                          const currentProgress = minZeroMaxOne(
                            currentStaggered * total
                          );

                          const isLast = index === textContent.length - 1;

                          const opacity = transform(
                            currentProgress,
                            [0, 0.3, 0.8, 1],
                            [0, 1, 1, 0]
                          );

                          const scale = transform(
                            currentProgress,
                            [0, 0.3, 0.8, 1],
                            [0.95, 1, 1, 0.95]
                          );

                          const finalTextOpacity = transform(
                            currentProgress,
                            [0, 0.3, 1],
                            [0, 1, 1]
                          );

                          const finalTextScale = transform(
                            currentProgress,
                            [0, 0.3, 1],
                            [0.95, 1, 1]
                          );

                          return (
                            <TextWrapper
                              key={index}
                              initial={{ x: "-50%", y: "-50%" }}
                              style={{
                                opacity: isLast ? finalTextOpacity : opacity,
                                scale: isLast ? finalTextScale : scale
                              }}
                            >
                              <Markdown>{text}</Markdown>

                              {isLast && (
                                <LinkButton
                                  style={{ marginTop: "3rem" }}
                                  to="/contact"
                                >
                                  Get in touch
                                </LinkButton>
                              )}
                            </TextWrapper>
                          );
                        })}
                      </TextContainer>
                    )}
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                      zIndex: 1
                    }}
                  >
                    <LeftOrbSection style={{ x, y, scale, rotate, opacity }}>
                      <Orb />
                    </LeftOrbSection>
                  </div>
                </OrbSectionContainer>

                <ProgressBar
                  style={{ opacity: progressBarOpacity }}
                  progress={progress.toFixed(2)}
                >
                  {/* <motion.span
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      transform: "translateY(100%)"
                    }}
                  >
                    progress: {progress.toFixed(2)}
                  </motion.span> */}
                </ProgressBar>
              </StickyItem>
            );
          }}
        />
      </Stack>
    </PageWrapper>
  );
}

export default IndexPage;

const StickyItem = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Stack = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-auto-flow: row;
  grid-gap: 6rem;
  width: 100%;
  height: 100%;
  margin-top: 27.5vh;

  @media (max-width: 950px) {
    margin-top: 19vh;
  }
`;

const OrbSectionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: minmax(0, 1.25fr) minmax(0, 1fr);
  grid-auto-flow: row;
  /* border: 3px solid red; */
`;

const LeftOrbSection = styled(motion.div)`
  width: 100%;
  height: 100%;
  -ms-transform: translateZ(0); /* IE 9 */
  -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
  transform: translateZ(0) scale3d(1, 1, 1);
`;

const TextContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateY(15%);
  /* border: 1px solid blue; */
`;

const TextWrapper = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  /* background: white; */
  max-width: 800px;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  will-change: transform, opacity;
  /* border: 1px solid red; */

  p,
  li {
    padding: 0 5%;
    font-size: clamp(2.6rem, 5vw, 3.15rem);
    font-family: "Open Sans", sans-serif;
    letter-spacing: 0;
    font-weight: 200;
    color: hsl(0 0% 30%);
    line-height: 1.1;

    strong {
      font-weight: 600;
      color: hotpink;
    }
  }
`;

const ProgressBar = styled(motion.div)<{ progress: string }>`
  background: #635ec0;
  width: 80%;
  height: 5px;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 500px;
  overflow: hidden;

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, pink, hotpink);
    top: 0;
    left: -100%;
    z-index: 2;
    transform: ${(p) => `translateX(${Number(p.progress) * 100}%)`};
    border-radius: 500px;
  }
`;
