import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { media } from "@styles";
import { clamp, getOffsetTop } from "@utils";

/*
  NOTES:
    This component is adapted from Narative's codebase. Full credit to them for the idea.
    https://github.com/narative/narative.co/blob/master/src/components/Sticky/Sticky.tsx

  USAGE:
    <Sticky
      height="2000px"
      cover
      render={({ progress }) => {
        return (
          <p>Element will be sticky for 2000px</p>
        )
      }}
    />
*/
interface StickyState {
  position: number;
  progress: number;
}

interface StickyProps {
  children?: React.ReactChildren;
  render: (props: StickyState) => React.ReactNode;
  height?: string;
  top?: number;
  disableOnMobile?: boolean;
  cover?: boolean;
}

function Sticky({ cover, height, render, top, disableOnMobile }: StickyProps) {
  const [position, setPosition] = useState(0);
  const [progress, setProgress] = useState(0);
  const element = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleScroll() {
      const $el: any = element.current;

      if ($el) {
        const scrollPosition = window.pageYOffset || window.scrollY;
        const topOfElement = getOffsetTop($el);
        const { top: topOfElementRelativeToDoc, height: heightOfElement } =
          $el.getBoundingClientRect();

        const scrollPositionRelativeToContainer =
          scrollPosition - topOfElementRelativeToDoc;

        const viewportHeight = Math.max(
          document.documentElement.clientHeight,
          window.innerHeight || 0
        );

        const position =
          scrollPositionRelativeToContainer < 0
            ? 0
            : scrollPositionRelativeToContainer;

        const progressOverElement =
          (scrollPosition - topOfElement) /
            (heightOfElement - viewportHeight) || 0;

        const progress = clamp(progressOverElement, 0, 1);

        setPosition(position);
        setProgress(progress);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [element]);

  return (
    <div ref={element} data-component="sticky">
      <StickyDuration height={height} isDisabled={disableOnMobile}>
        <StickyItemContainer>
          <StickyItem top={top} cover={cover} isDisabled={disableOnMobile}>
            {render({ progress, position })}
          </StickyItem>
        </StickyItemContainer>
      </StickyDuration>
    </div>
  );
}

export default Sticky;

const StickyDuration = styled.div<{
  height?: string;
  isDisabled?: boolean;
}>`
  height: ${(p) => p.height || "100vh"};

  ${media.tablet`
    height: ${(p: { height?: string; isDisabled?: boolean }) =>
      p.isDisabled ? "100%" : p.height}
  `}
`;

const StickyItemContainer = styled.div`
  height: 100%;
`;

const StickyItem = styled.div<{
  top?: number;
  cover?: boolean;
  isDisabled?: boolean | undefined;
}>`
  position: sticky;
  top: ${(p) => p.top || 0}px;
  min-height: initial;
  height: ${(p) => (p.cover ? "100vh" : "initial")};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  width: 100%;
  ${(p) => p.cover && "overflow-y: hidden;"};
  ${media.tablet`
    position: ${(p: { isDisabled: string }) =>
      p.isDisabled ? "static" : "sticky"};
    display: ${(p: { isDisabled: string }) =>
      p.isDisabled ? "block" : "flex"};
  `}
`;
